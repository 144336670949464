
import { ref, onMounted, watch, computed, reactive } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import BrowseCard from '@/components/cards/BrowseCard.vue';

import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat, numberFormat, mappedStatus } from '@/utils/helper/format';
import { ServicesTypes } from '@/core/data/constance';
import { useRoute } from 'vue-router';
export default {
  name: 'SalesReport',
  components: {
    Datatable,
    BrowseCard,
  },
  setup() {
    const route = useRoute();
    const { storeId } = route.params;
    const { startdate, enddate } = route.query;
    const tableRef = ref<any>(null);
    const loading = ref(false);
    const models = ref({
      packageName: null,
      status: null,
      date: [] as any,
    });

    const summary = reactive({
      data: { cancel: 0, complete: 0, total: 0 },
      total: { cancel: 0, complete: 0, total: 0 },
    });
    const tableState = ref({
      header: [
        { name: '#', key: 'checkedBox', sortable: false },
        { name: 'Order ID', key: 'showId', sortable: false },
        { name: 'Store', key: 'store', sortable: false },
        { name: 'Car', key: 'showCarBrand', sortable: false },
        { name: 'Start Date', key: 'showStartDate', sortable: false },
        { name: 'End Date', key: 'showEndDate', sortable: false },
        { name: 'Price', key: 'showTotalPrice', sortable: false },
        { name: 'Net Price', key: 'showNetPrice', sortable: false },
        { name: 'Status', key: 'status', sortable: false },
        { name: 'More Detail', key: 'moreDetail', sortable: false },
      ],
      tableData: [],
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
    });

    onMounted(() => {
      const pageName = storeId ? 'Sales Report' : 'Store Report';
      models.value.date =
        startdate && enddate
          ? [
              new Date(parseInt(`${startdate}`)),
              new Date(parseInt(`${enddate}`)),
            ]
          : [];
      setCurrentPageBreadcrumbs('Dashboard', [pageName], {});
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        tableState.value.currentPage = page;
        tableState.value.rowsPerPage = rows;

        if (tableState.value.rowsPerPage + 1 > tableState.value.total) {
          tableState.value.currentPage = 1;
        }

        await search(
          tableState.value.currentPage,
          tableState.value.rowsPerPage
        );
      }
    );

    watch(
      () => route.query,
      ({ startdate, enddate }) => {
        models.value.date =
          startdate && enddate
            ? [
                new Date(parseInt(`${startdate}`)),
                new Date(parseInt(`${enddate}`)),
              ]
            : [];
      }
    );

    const exportExcel = async () => {
      // const queryCondition = {};
      // const { data } = await ApiService.get(
      //   `/report/sale-report-excel?${new URLSearchParams(queryCondition)}`
      // );

      const excelData: any = [];
      excelData.push({
        หมายเลข: 1,
        ยอดการแทง: 2,
        ราคาเฉลีย: 3,
        รวม: 4,
      });
      return excelData;
    };

    const search = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
        };

        const queryConditionAll: any = {};

        const { packageName, status } = models.value;

        if (models.value.date[0]) {
          console.log(dateFormat(models.value.date[0]));
          queryCondition[`start`] = new Date(
            dateFormat(models.value.date[0])
          ).getTime();

          queryConditionAll[`start`] = new Date(
            dateFormat(models.value.date[0])
          ).getTime();
        }

        if (models.value.date[1]) {
          queryCondition[`end`] = new Date(
            dateFormat(models.value.date[1])
          ).getTime();

          queryConditionAll[`end`] = new Date(
            dateFormat(models.value.date[1])
          ).getTime();
        }

        if (storeId) {
          queryCondition[`store`] = storeId;
          queryConditionAll[`store`] = storeId;
        }

        if (packageName) {
          queryCondition[`packageName`] = packageName;
        }

        if (status) {
          queryCondition[`status`] = status;
        }

        const { data } = await ApiService.get(
          `/report/sale-report?${new URLSearchParams(queryCondition)}`
        );
        if (data.status) {
          tableState.value.total = data.total;
          tableState.value.tableData = data.datas;
        }

        const { data: summaryData } = await ApiService.get(
          `/report/summary-sale-report?${new URLSearchParams(queryCondition)}`
        );

        const { data: summaryTotal } = await ApiService.get(
          `/report/summary-sale-report?${new URLSearchParams(
            queryConditionAll
          )}`
        );

        if (summaryData.status) {
          summary.data.cancel = summaryData.cancel;
          summary.data.complete = summaryData.complete;
          summary.data.total = summaryData.total;
        }

        if (summaryTotal.status) {
          summary.total.cancel = summaryTotal.cancel;
          summary.total.complete = summaryTotal.complete;
          summary.total.total = summaryTotal.total;
        }

        tableState.value.loading = false;
      } catch (error) {
        tableState.value.loading = false;
      }
    };

    const summaryTemplate = computed(() => [
      {
        title: '',
        contents: [
          { label: 'Completed', key: summary.data?.complete },
          { label: 'Canceled', key: summary.data?.cancel },
          { label: 'Total', key: summary.data?.total },
        ],
      },
    ]);

    const totals = computed(() => [
      { label: 'Total Completed', key: summary.total?.complete },
      { label: 'Total Canceled', key: summary.total?.cancel },
      { label: 'Total Orders', key: summary.total?.total },
    ]);

    return {
      dateFormat,
      exportExcel,
      loading,
      mappedStatus,
      models,
      numberFormat,
      search,
      summaryTemplate,
      tableRef,
      tableState,
      totals,
      orderPackage: computed(() => {
        return (
          ServicesTypes.find((p) => p.value == models.value.packageName)
            ?.name || 'All'
        );
      }),
    };
  },
};
