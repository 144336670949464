<template>
  <div class="card mb-8">
    <div class="card-body gy-5">
      <div
        class="d-flex justify-content-between align-items-center flex-wrap"
        style="width: 100%"
      >
        <div
          v-if="!isBrowsing"
          class="d-flex justify-content-left align-items-center flex-wrap"
        >
          <h5 class="mb-0 me-3">
            <span>Result from: </span>
          </h5>
          <span
            v-if="
              !modelValue.date.length &&
              !modelValue.packageName &&
              !modelValue.status
            "
            class="fw-bolder text-muted mt-0 fs-5"
            >{{ !isBrowsing ? (isLoaded ? 'All' : 'No Data') : null }}</span
          >
          <div v-if="modelValue.date.length" class="gy-3">
            <span class="d-block d-lg-inline">{{
              moment(modelValue.date[0]).format('DD/MM/YYYY') +
              ' - ' +
              moment(modelValue.date[1]).format('DD/MM/YYYY')
            }}</span>
          </div>
          <h5 class="m-0" v-if="modelValue.packageName">
            <span class="ps-3">Package: </span>
          </h5>
          <div v-if="modelValue.packageName" class="ps-5 gy-3">
            <span class="d-block d-lg-inline">{{
              ServicesTypes.find((p) => p.value == modelValue.packageName).name
            }}</span>
          </div>
          <h5 class="m-0" v-if="modelValue.status">
            <span class="ps-3">Status: </span>
          </h5>
          <div v-if="modelValue.status" class="ps-5 gy-3">
            <span class="d-block d-lg-inline">{{ modelValue.status }}</span>
          </div>
        </div>
        <div
          class="d-flex flex-row align-items-center justify-content-center flex-wrap"
          v-if="isBrowsing"
        >
          <span class="m-0 fs-5 fw-bolder">Browse:</span>
          <div
            class="d-flex me-xxl-15 me-5 align-items-center justify-content-center"
          >
            <select
              class="form-select form-select-solid me-5 ms-5 ms-xxl-0"
              name="Package"
              v-model="model.packageName"
            >
              <option :value="null">Order Package</option>
              <option
                v-for="item in ServicesTypes"
                :value="item.value"
                :key="item.name"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              class="form-select form-select-solid"
              name="order status"
              v-model="model.status"
            >
              <option :value="null">Order Status</option>
              <option
                v-for="item in reportStatus"
                :value="item.value"
                :key="item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div
            class="d-flex me-xxl-15 me-3 align-items-center justify-content-center"
          >
            <span class="fs-5 fw-bolder me-3">Date:</span>
            <el-date-picker
              v-if="!isLockedDate"
              style="max-width: 250px; min-width: 250px"
              v-model="model.date"
              type="daterange"
              class="my-2 my-xxl-0"
              start-placeholder="DD/MM/YY"
              end-placeholder="DD/MM/YY"
            >
            </el-date-picker>
            <span v-else class="d-block d-lg-inline">{{
              moment(modelValue.date[0]).format('DD/MM/YYYY') +
              ' - ' +
              moment(modelValue.date[1]).format('DD/MM/YYYY')
            }}</span>
          </div>
        </div>
        <button
          type="button"
          class="btn"
          :class="isBrowsing ? 'btn-primary' : 'btn-success'"
          name="browse_btn"
          @click="toggleBrowsing"
        >
          <span v-if="!isBrowsing" class="svg-icon svg-icon-4">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          {{ isBrowsing ? 'Show Result' : 'Browse Sales' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import moment from 'moment';
import { ServicesTypes, reportStatus } from '@/core/data/constance';
import { useRoute } from 'vue-router';

export default {
  name: 'BrowseCard',
  props: {
    modelValue: Object,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const { startdate, enddate } = route.query;
    const isLockedDate = ref(false);
    const isLoaded = ref(true);
    const isBrowsing = ref(false);
    const copy = [...Object.values(props.modelValue)];
    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const toggleBrowsing = () => {
      isBrowsing.value = !isBrowsing.value;
      if (!isBrowsing.value) {
        isLoaded.value = true;
        emit('search');
      }
    };

    onMounted(() => {
      isLockedDate.value = !!startdate && !!enddate;
    });

    watch(
      () => route.query,
      (query) => {
        const { startdate: start, enddate: end } = query;
        isLockedDate.value = !!start && !!end;
      }
    );

    return {
      moment,
      model,
      copy,
      isBrowsing,
      ServicesTypes,
      reportStatus,
      isLoaded,
      toggleBrowsing,
      isLockedDate,
    };
  },
};
</script>

<style lang="sass" scoped>
select
</style>

<style lang="sass">
.el-date-editor, .el-range-input
  background-color: #F5F8FA !important
  border: 0 !important

.el-range-input
  font-weight: 500 !important

.el-range-input::placeholder
  color: black !important
</style>
